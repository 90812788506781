<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="请选择" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>“UniLive”开店协议</h2>
		<p>《UniLive开店协议》，是Pix Technology PTE. LTD.（以下简称“我方”）和你方（你方为自然人、法人或其他组织）所约定的规范双方权利和义务的具有法律效力的电子协议，下称“本协议”。你方勾选“我同意”或点击“我已阅读并遵守该协议”按钮，即表示你方已经仔细阅读、充分理解并完全地毫无保留地接受本协议的所有条款。
		</p>

		<h3>第一条 协议目的</h3>
		<p>本协议旨在明确商家与UniLive平台之间的权利与义务，规范商家的经营行为，维护消费者权益，促进UniLive平台的健康发展，确保各方在交易中的合法权益。
		</p>

		<h3>第二条 定义</h3>
		<p>1、UniLive：指本应用程序及其相关服务的运营方，提供线上交易及服务支持的平台。</p>
		<p>
			2、商家：指在UniLive上注册并开展商业活动的个人或企业，包括但不限于个体工商户、公司及其他法人实体。
		</p>
		<p>
			3、消费者：指在UniLive上购买商品或服务的用户。
		</p>

		<h3>第三条 商家入驻</h3>
		<p>
			1、注册要求：商家须在UniLive上填写真实、准确的个人或企业信息，包括姓名、联系方式、营业执照、税务登记证等。商家应确保注册信息的及时更新，如信息发生变化，须在48小时内进行更新。

		</p>
		<p>2、审核流程：UniLive将对商家提交的信息进行审核，审核时间通常为3-5个工作日。审核通过后，商家将收到确认通知，开店功能正式启用。
		</p>
		<p>3、店铺设置：商家需在UniLive上设置店铺，包括店铺名称、Logo、简介、商品分类等，确保信息符合平台规定。
		</p>

		<h3>第四条 商家的权利与义务</h3>
		<p>
			1、在UniLive上发布商品信息并进行销售，享受平台提供的各项服务。
		</p>
		<p>2、参与UniLive的促销活动和市场推广，提升曝光率。</p>
		<p>3、获取销售数据、用户反馈和市场分析报告，以优化经营策略。</p>
		<p>4、商品合规性：确保所售商品的合法性、真实性和合规性，不得销售假冒伪劣产品。</p>
		<p>5、信息更新：定期更新商品信息，确保价格、库存、配送信息准确、清晰。</p>
		<p>6、遵守平台规则：遵守UniLive的各项管理规定和操作规范，参与平台培训，提升经营能力。</p>

		<h3>第五条 UniLive的权利与义务</h3>
		<p>1、对商家发布的信息进行审核和管理，确保信息的合规性。</p>
		<p>2、对违反协议的商家采取相应措施，包括但不限于警告、暂停或终止其店铺，直至法律诉讼。</p>
		<p>3、根据市场情况和法律法规的变化，定期更新平台政策，商家应及时了解并遵守。</p>
		<p>4、提供安全、稳定的交易平台，保障商家和消费者的交易安全，防范诈骗和其他违法行为。</p>
		<p>5、及时处理商家的问题和反馈，提供必要的技术支持和培训。</p>
		<p>6、定期向商家提供经营数据分析、市场趋势报告及推广建议，帮助商家提升业绩。</p>

		<h3>第六条 违约责任</h3>
		<p>
			1、若商家违反本协议的任何条款，UniLive有权要求商家进行整改，并可视情节轻重采取相应的惩罚措施，包括但不限于降低店铺等级、限制商品上架、终止服务等。
		</p>
		<p>2、因商家原因导致的消费者投诉，商家应承担相应的法律责任和赔偿责任。</p>
		<p>3、若UniLive未能按照协议约定提供服务，商家可要求UniLive赔偿损失，具体赔偿金额由双方协商确定。</p>

		<h3>第七条 协议的变更与解除</h3>
		<p>1、本协议的任何修改或变更需经双方协商一致，方可解除</p>
		<p>2、uniLive有权根据法律法规、市场变化等因素定期对协议进行修订，并及时通知商家。</p>
		<p>3、商家如希望解除本协议，应提前7天书面通知UniLive，并在解除前妥善处理未完成的订单和消费者投诉。</p>
		<p>4、UniLive有权在发现商家存在严重违约行为时，立即解除本协议，无需提前通知。</p>

		<h3>第八条 其他条款</h3>
		<p>1、保密条款：双方应对在协议履行过程中知悉的商业秘密及其他信息保密，不得向第三方披露。
		</p>
		<p>2、不可抗力：如因不可抗力因素（如自然灾害、战争、政策变化等）导致无法履行协议，双方不承担违约责任。
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/StoreOpeningAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/StoreOpeningAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/StoreOpeningAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/StoreOpeningAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/StoreOpeningAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/StoreOpeningAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/StoreOpeningAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/StoreOpeningAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/StoreOpeningAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/StoreOpeningAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/StoreOpeningAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/StoreOpeningAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/StoreOpeningAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/StoreOpeningAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>